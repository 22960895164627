import Header from "../components/header";
import Main from "../components/main";
import FrontAbout from "../components/front-about";
import Footer from "../components/footer";
import '../index.css';

const Home = () => {
  
    return (
        <div className="App">
        <div className="main-header">
          <Header/>
        </div>
        <div>
          <Main/>
          </div>
          <div>
          <FrontAbout/>
          </div>

  
          <Footer/>
      </div>
    )
}

export default Home;