import { useEffect } from "react";


const Footer = () => {

    useEffect(() => {
        frameSize();  
    },[])

    const frameSize = () => {
        let height = 0;
        let weigth = 0;
    
        if(window.screen.width <= 500){
            height = 250;
            weigth = 350;
        }else{
            height = 300;
            weigth = 400;
        }

        const data = {
            height,
            weigth
        };
        return data;
    }
    
    return (

<footer className="text-white lg:my-[75px] bg-black buttons" id='footer'>
    <div className="lg:mx-[200px] lg:mt-0 sm:mt-20 lg:max-w-full lg:max-w-screen-xl md:max-w-[750px] text-[18px]">
      <div className="lg:grid lg:grid-cols-4 lg:gap-12 px-4">
        <div className="lg:ml-0 sm:ml-10">
            <ul className=" font-medium sm:pb-10">             
                <li className="mb-4">
                    <a href="#" className=" hover:underline">+372 56237237</a>
                </li>
            
            </ul>
        </div>
        <div className="lg:ml-0 sm:ml-10">
            <ul className=" font-medium sm:pb-10">
                <li className="mb-4">
                    <a href="https://www.facebook.com/Rempire.Official.Tallinn" className="hover:underline">Facebook</a>
                </li>
                <li className="mb-4">
                    <a href="https://www.instagram.com/rempire.official/" className="hover:underline">Instagram</a>
                </li>
            </ul>
        </div>
        <div className="lg:ml-0 sm:ml-10">
            <ul className="font-medium sm:pb-10">
                <li className="mb-4">
                    <a href="#" className="hover:underline">REMPIRE, Tallinn, Mardi 1</a>
                </li>
            </ul>
        </div>
        <div>
            <ul className="font-medium">
                <li className="mb-4">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2029.1300876166874!2d24.759413577182798!3d59.430904902885864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46929595e60f6d85%3A0xee836da238614059!2sREMPIRE!5e0!3m2!1sen!2see!4v1697973391658!5m2!1sen!2see" width={frameSize().weigth} height={frameSize().height} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </li>
            </ul>
        </div>

    </div>
    </div>
</footer>

    )
}

export default Footer;

