import Home from "./pages/Home";
import { Routes, Route } from 'react-router-dom';
import Barbershop from "./pages/Barbershop";
import Tatoo from "./pages/Tatoo";
import './index.css';
import { useTranslation } from 'react-i18next';


function App() {

  const { t } = useTranslation();

  // Set meta tags dynamically based on the current language
  document.querySelector('meta[name="description"]').setAttribute('content', t('description'));
  document.querySelector('meta[name="keywords"]').setAttribute('content', t('keywords'));



  return (
  <>
  <Routes>
     <Route path="/" element={<Home />} />
     <Route path="/barbershop" element={<Barbershop />} />
     <Route path="/tattoo" element={<Tatoo />} />
  </Routes>
</>

  );
}

export default App;
