import Header from "../components/header";
import person from "../images/person.jpg";
import naine from "../images/naine.jpg";
import vlad from "../images/vlad.jpg";
import video from "../images/video.gif";
import renat from "../images/renat.jpg";
import Footer from "../components/footer";
import CarouselS from "../components/carousel";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import Loading from "./Loading";

const Barbershop = () => {

    const { t } = useTranslation();
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
      const images = [person, naine, vlad, video, renat];
      let loadedCount = 0;
    
      const handleImageLoad = () => {
        loadedCount++;
        if (loadedCount === images.length) {
          setImagesLoaded(true);
        }

      };
    
      images.forEach(imageSrc => {
        const img = new Image();
        img.onload = handleImageLoad;
        img.src = imageSrc;
      });

    }, []); 
    
    if (!imagesLoaded) {
      return <Loading />;
    }

    return (
        <>
        <div className="main-header">
          <Header/>
        </div>
        <div className="flex items-center justify-center mt-[100px] sm:w-full">
        <div className="z-20 lg:text-8xl sm:text-5xl sm:mr-[-150px] sm:ml-10 front-page">
            <p className="text-[#e9fc53] ">{t("best")}</p>
            <p>{t("without")}</p>
            <p>{t("extra")}</p>
        </div>
        <div>
        <img src={video} className="lg:w-[700px] sm:w-[300px] sm:ml-[-20px] h-[500px] object-none"/>
                <div className="lg:float-right lg:mt-[-450px] sm:mt-[-470px] lg:mr-[-50px] h-[500px] lg:w-[500px] sm:mr-10 sm:ml-[150px] sm:w-[200px] bg-[#aaa]">
            </div>
       
        </div>
        </div>"
        <div className="flex md:flex-wrap lg:flex-row sm:flex-col lg:justify-center mt-[100px]">

    <div className="relative max-w-[300px] w-full sm:w-auto m-20">
        <div className="relative w-full h-[400px]">
            <img src={person} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-center items-center bg-white p-4">
            <p className="text-black pt-[10px]">Valentin Nachinov</p>
            <div className="h-[50px] mt-[20px] cursor-pointer">
                <a href="https://b353848.alteg.io/company/337850/menu?o=m2063422" className="text-black bg-[#e9fc53] border-0 px-10 py-5 no-underline cursor-pointer">
                    {t('book_now')}
                </a>
            </div>
        </div>
    </div>

    <div className="relative max-w-[300px] w-full sm:w-auto m-20">
        <div className="relative w-full h-[400px]">
            <img src={naine} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-center items-center bg-white p-4">
            <p className="text-black pt-[10px]">Milena Orehhova</p>
            <div className="h-[50px] mt-[20px] cursor-pointer">
                <a href="https://b353848.alteg.io/company/337850/menu?o=m1599961" className="text-black bg-[#e9fc53] border-0 px-10 py-5 no-underline cursor-pointer">
                    {t('book_now')}
                </a>
            </div>
        </div>
    </div>

    <div className="relative max-w-[300px] w-full sm:w-auto m-20">
        <div className="relative w-full h-[400px]">
            <img src={vlad} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-center items-center bg-white p-4">
            <p className="text-black pt-[10px]">Vladislav Berezan</p>
            <div className="h-[50px] mt-[20px] cursor-pointer">
                <a href='https://b353848.alteg.io/company/337850/menu?o=m992690' className="text-black bg-[#e9fc53] border-0 px-10 py-5 no-underline cursor-pointer">
                    {t('book_now')}
                </a>
            </div>
        </div>
    </div>

    <div className="relative max-w-[300px] w-full sm:w-auto m-20">
        <div className="relative w-full h-[400px]">
            <img src={renat} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-center items-center bg-white p-4">
            <p className="text-black pt-[10px]">Renat Gayanov</p>
            <div className="h-[50px] mt-[20px] cursor-pointer">
                <a href="https://b353848.alteg.io/company/337850/menu?o=m991638" className="text-black bg-[#e9fc53] border-0 px-10 py-5 no-underline cursor-pointer">
                    {t('book_now')}
                </a>
            </div>
        </div>
    </div>



            </div>

            <div className="flex sm:flex-col lg:flex-row lg:justify-around lg:mx-0 text-white mt-[150px] lg:mb-[300px] sm:mx-10 front-page">
                <div className="text-8xl lg:mb-0 sm:mb-20">
                    <h1 className="sticky">{t("faq")}</h1>
                </div>
                <div>
                    <div className="mb-10">
                        <h1 className="text-6xl mb-4">
                        {t("appointment")}
                        </h1>
                        <p className="text-[#b8b8b8] text-xl">
                        {t("appointmentA")}
                        </p>
                        <p className="text-[#b8b8b8] text-xl">
                        {t("appointmentB")}
                        </p>
                    </div>
                    <div>
                        <h1 className="text-6xl mb-4">
                        {t("much")}
                        </h1>
                        <p className="text-[#b8b8b8] text-xl">
                        {t("muchA")}
                        </p>
                    </div>
                </div>
            </div>
            <CarouselS/>
            <Footer/>
        </>
    )
}

export default Barbershop;