
import barber from '../images/barber.jpeg'
import { useTranslation } from 'react-i18next';

const FrontAbout = () => {
    const { t } = useTranslation();

    return (
        <div className="relvative lg:w-full lg:h-[1200px]  lg:mt-[200px] bg-[#aaa] front-page">
        <div className="flex items-center justify-center lg:px-[100px]">
            <div className="z-20 lg:text-8xl lg:ml-0 sm:text-5xl sm:ml-10">
                <p className='text-[#e9fc53]'>{t("salon")}</p>
                <p>{t("dedicated")}</p>
                <p>{t("unique")}</p>
            </div>
            <div className='lg:ml-[-150px] md:ml-[-100px] sm:ml-[-350px] lg:mt-[200px]'>
                <img src={barber} className="z-10 lg:w-[700px]  sm:w-[90%] lg:h-[500px] lg:ml-[-20px] sm:h-[800px] object-none"/>
                <div className="lg:block md:hidden sm:hidden lg:float-right lg:mt-[-450px] lg:mr-[-20px] lg:h-[500px] lg:w-[500px] bg-gray-600">
                </div>
            </div>
        </div>
        <div className='lg:block lg:mx-[200px] lg:text-[32px] lg:mt-[100px] sm:hidden'>
                <p>{t("about")}</p>
            </div>
        </div>
    )   
}

export default FrontAbout;


