import slider1 from '../images/tslider1.jpg';
import slider2 from '../images/tslider2.jpg';
import slider3 from '../images/tslider3.jpg';
import { Carousel } from 'flowbite-react';

const TatooSlider = () => {

    return (
        <Carousel className='h-[900px] w-[1100px] mx-auto hover:outline-none focus:outline-none active:outline-none lg:block sm:hidden'>
        <img
          alt="..."
          className='bg-contain'
          src={slider1}
        />
        <img
          alt="..."
          className='bg-auto'
          src={slider2}
        />
        <img
          alt="..."
          className='bg-auto'
          src={slider3}
        />
      </Carousel>
    )
}

export default TatooSlider;