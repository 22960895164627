import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import mainLogo from "../images/logo.png";
import '../index.css';
import { useTranslation } from 'react-i18next';
import 'flowbite';
import { Dropdown } from 'flowbite-react';


const Header = () => {

  const { t, i18n } = useTranslation();

  const languages = {
    ee:'EST',
    ru:'РУС',
    en:'ENG'
  }

  const currentLang = Object.keys(languages).find((x) => x === i18n.language);
 
  const [activeLanguage,setActiveLanguage] = useState(languages[currentLang]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setActiveLanguage(languages[language]);
  };

  const onClick = () => {
    const menu = document.getElementById('navbar-default');
    menu.classList.toggle("hidden");
  }

    return (
        

<nav className="relative bg-black border-gray-200 dark:bg-gray-900">
  <div className="flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="https://rempire.ee" className="flex items-center">
        <img src={mainLogo} className="z-20 h-12 mr-3" alt="Flowbite Logo" />
    </a>
    <button data-collapse-toggle="navbar-default" type="button" onClick={onClick} className="inline-flex z-50 items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg lg:hidden" aria-controls="navbar-default" aria-expanded="false">
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div className="relative z-50 hidden w-full lg:block lg:w-auto" id="navbar-default" >
      <ul className="bg-black flex flex-col items-center lg:flex-row lg:space-x-8 sm:justify-center  text-white">
      <li className='cursor-pointer sm:mb-5 lg:mb-0'>
          <Link to="/" className="!line-through py-4 pl-3 pr-4 text-white font-semibold tracking-widest uppercase transition-transformease-in-out duration-300 hover:ft hover:tracking-[0.25rem]" >Home</Link>
        </li>
        <li className='cursor-pointer sm:mb-5 lg:mb-0'>
          <Link target="_blank" to="https://rempireshop.com/" className="block py-2 pl-3 pr-4 text-white hover:cursor-pointer tracking-widest uppercase transition-transformease-in-out duration-300 hover:ft hover:tracking-[0.25rem]">{t("shop")}</Link>
        </li>
        <li className='cursor-pointer sm:mb-5 lg:mb-0'>
        <Link to="https://www.rempire.ee/barbershop" className='cursor-pointer tracking-widest uppercase transition-transformease-in-out duration-300 hover:ft hover:tracking-[0.25rem]'>
          <p className="block py-2 pl-3 pr-4 text-white cursor-pointer">{t("barbershop")}</p>
          </Link>
        </li>
        <li className='cursor-pointer sm:mb-5 lg:mb-0'>
        <Link to="https://www.rempire.ee/tattoo" className='cursor-pointer tracking-widest uppercase transition-transformease-in-out duration-300 hover:ft hover:tracking-[0.25rem]'>
          <p className="block py-2 pl-3 pr-4 text-white cursor-pointer">{t("tattoshop")}</p>
          </Link>
        </li>
        <li className='cursor-pointer sm:mb-5 lg:mb-0 tracking-widest uppercase transition-transformease-in-out duration-300 hover:ft hover:tracking-[0.25rem]'>
          <a href="#footer" className="block py-2 pl-3 pr-4 text-white">{t("contact")}</a>
        </li>
        <li className='cursor-pointer sm:mb-5 lg:mb-0'>
          <Dropdown renderTrigger={() => <p className='cursor-pointer pr-4'>{activeLanguage}</p>} className='outline-0 !border-none !outline-none hover:bg-black active:outline-none active:border-none focus:outline-none bg-black py-2 pr-4 text-white cursor-pointer'>
          {(Object.keys(languages)).map((lan,key) => {
              return (
            <Dropdown.Item key={key} className='text-md focus:bg-black active:bg-black hover:bg-black'><a href="#" className="py-2 pl-3 pr-4 text-white cursor-pointer bg-black hover:bg-black" onClick={() => changeLanguage(lan)}>{languages[lan]}</a></Dropdown.Item>
            )})}
          </Dropdown>
        </li>
      </ul>
    </div>
  </div>
</nav>

    )
}
export default Header;
